import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  alpha,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomTextField } from "../../../../components/shared/input/BootStrapInput";

const FindMessageWithComments = ({ findMessageWithCommentsLogic }) => {
  const { control, handleSubmit, errors, isLoading, theme, onSubmit } =
    findMessageWithCommentsLogic;

  return (
    <>
      <Typography variant="h6">
        Get <span style={{ color: theme.palette.primary.main }}>comments</span>{" "}
        from your chosen telegram channel
      </Typography>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <FormLabel>Message link</FormLabel>
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <CustomTextField
              {...field}
              type="link"
              placeholder="https://t.me/channelName/2874"
              className={errors?.link?.message ? "error" : ""}
              sx={{ border: `1px solid ${theme.palette.divider}` }}
            />
          )}
        />
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors?.link?.message}
        </FormHelperText>
      </FormControl>
      <Button
        sx={{
          marginTop: "20px",
          marginRight: "auto",
          display: "flex",
          textTransform: "none",
          padding: "12px 25px",
          fontSize: "14px",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "&:disabled": {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            color: theme.palette.primary.contrastText,
          },
        }}
        onClick={handleSubmit(onSubmit)}
        endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        disabled={isLoading || errors?.link?.message}
      >
        Send Link
      </Button>
    </>
  );
};

export default FindMessageWithComments;
