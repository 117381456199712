import * as yup from "yup";

const telegramUrlPattern = /^https:\/\/t\.me\/[\w_]+\/\d+$/;

export const schema = yup.object().shape({
  link: yup
    .string()
    .required("Link is required")
    .matches(
      telegramUrlPattern,
      "Link must be a valid Telegram URL, containing a channel and ending with an ID"
    ),
});
