import React from "react";
import Papa from "papaparse";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { schema } from "./schema/findMessageWithCommentsSchema";
import * as XLSX from "xlsx";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTheme } from "@mui/material";
import { useForm } from "react-hook-form";

const useFindMessageWithComments = () => {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { mutateAsync, isLoading, isError, error } = useMutateQuery();
  const { enqueueSnackbar } = useSnackbar();

  const downloadExcel = (jsonData) => {
    const modifiedData = jsonData?.data.map((item) => {
      return {
        Link: item?.Link,
        Name: item?.first_name,
        Username: item?.username,
        Date: item?.Date,
        Time: item?.Time,
        Comment: item?.Comment,
      };
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${new Date().toISOString()}.xlsx`);
  };

  const onSubmit = async (data: any) => {
    const res = await mutateAsync({
      url: "/fetch_single_message_commint",
      body: {
        message_id: data?.link,
      },
      method: "post",
    });

    const result = Papa.parse(res, {
      header: true,
      skipEmptyLines: true,
    });

    downloadExcel(result);
  };

  useEffect(() => {
    if (isError) {
      try {
        let errorMsg: any = error;
        enqueueSnackbar(errorMsg?.response?.data, { variant: "error" });
      } catch (error) {
        enqueueSnackbar("something went wrong", { variant: "error" });
      }
    }
  }, [isError]);

  const findMessageWithCommentsLogin = {
    control,
    handleSubmit,
    errors,
    isLoading,
    theme,
    onSubmit,
  };

  return findMessageWithCommentsLogin;
};

export default useFindMessageWithComments;
