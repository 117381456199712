import * as yup from "yup";

export const schema = yup
  .object({
    username: yup
      .string()
      .transform((value) => value.trim())
      .required("username is required"),
    password: yup.string().required("password is required"),
  })
  .required();
