import { useMutation, useQueryClient } from "react-query";
import { api } from "./interceptor";

type CreateFnTypes = {
  url: string;
  body?: any;
  headers?: any;
  method: "post" | "patch" | "delete";
};

export const useMutateQuery = () => {
  const queryClient = useQueryClient();

  const createElement = async ({
    url,
    body,
    method,
    headers,
  }: CreateFnTypes) => {
    const { data } = await api[method](url, body, {
      headers: headers,
    });
    return data;
  };

  return useMutation(createElement, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
