import { createContext, useContext, useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import dayjs from "dayjs";

const ThemeModeContext = createContext({
  toggleThemeMode: () => {},
  mode: "light",
});

export const useThemeMode = () => useContext(ThemeModeContext);

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6456F8",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#d5e8cf",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
    },
    warning: {
      main: "#FFC322",
      contrastText: "#000000",
    },
    error: {
      main: "#FF5449",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#6456F8",
      contrastText: "#170F49",
    },
    background: {
      default: "#FFFFFF",
      paper: "#f7faf8",
    },
    text: {
      primary: "#0a2f0d",
      secondary: "#6B736C",
    },
    divider: "#e8efe9",
  },
  typography: {
    fontFamily: `'Nunito Sans', sans-serif;`,
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6456F8",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#d5e8cf",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
    },
    warning: {
      main: "#FFC322",
      contrastText: "#000000",
    },
    error: {
      main: "#FF5449",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#6456F8",
      contrastText: "#170F49",
    },
    background: {
      default: "#FFFFFF",
      paper: "#f7faf8",
    },
    text: {
      primary: "#0a2f0d",
      secondary: "#6B736C",
    },
    divider: "#e8efe9",
  },
  typography: {
    fontFamily: `'Nunito Sans', sans-serif;`,
  },
});

const getInitialThemeMode = () => {
  const hour = new Date().getHours();
  if (hour >= 6 && hour < 18) {
    return "light";
  } else {
    return "dark";
  }
};

export const ThemeModeProvider = ({ children }) => {
  const localStorageTheme = localStorage.getItem("theme");
  const [mode, setMode] = useState(localStorageTheme || getInitialThemeMode());

  const theme = useMemo(() => {
    return mode === "light" ? lightTheme : darkTheme;
  }, [mode]);

  const toggleThemeMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    localStorage.setItem("theme", mode === "light" ? "light" : "light");
  };

  return (
    <ThemeModeContext.Provider value={{ toggleThemeMode, mode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};
