import { Autocomplete } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const CustomTextField = styled("input")(
  ({ theme }) => `
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid ${theme.palette.divider};
  background-color: ${
    theme.palette.mode === "dark" ? theme.palette.background.paper : `#000B1808`
  };
  outline: 0;
  color: ${theme.palette.text.secondary};

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 3px ${alpha(theme.palette.primary.main, 0.26)};
    -webkit-box-shadow: 0 0 0 3px ${alpha(theme.palette.primary.main, 0.26)};;
    -moz-box-shadow: 0 0 0 3px ${alpha(theme.palette.primary.main, 0.26)};;
  }

  &.error {
    border-color: ${theme.palette.error.main};
    box-shadow: 0 0 0 3px ${alpha(theme.palette.error.main, 0.4)};
    -webkit-box-shadow: 0 0 0 3px ${alpha(theme.palette.error.main, 0.4)};
    -moz-box-shadow: 0 0 0 3px ${alpha(theme.palette.error.main, 0.4)};
  }

  &::placeholder {
    color: ${theme.palette.text.secondary};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
    padding: 8px 10px;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 0.625rem;
    padding: 6px 8px;
  }
`
);

export const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    transition: "0.2s ease-in-out all",

    borderRadius: "6px",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.background.paper
        : `#000B1808`,
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
    // boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
    outline: `0.2rem solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    border: `1px solid #D3302F00`,
    // boxShadow: ` 0 0 0 0.2rem`,
    outline: `0.2rem solid #F005`,
  },

  "& .MuiInputLabel-root": {
    display: "none",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "0.9rem",
    padding: "11px 14px",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiOutlinedInput-input": {
      fontSize: "0.8rem",
      padding: "10px 12px",
    },
  },
}));
