import * as yup from "yup";

const telegramUrlPattern = /^https:\/\/t\.me\//;

export const schema = yup.object().shape({
  link: yup
    .string()
    .required()
    .matches(
      telegramUrlPattern,
      "Link must be a valid Telegram URL"
    ),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  withReplies: yup.boolean(),
});
