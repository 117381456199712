import "./date-range-picker-mui.scss";

const DateRangePickerMui = ({
  startDate = "DD/MM/YYYY",
  endDate = "DD/MM/YYYY",
  handleClick,
  style,
}: any) => {
  return (
    <button
      style={style}
      className="input-range"
      onClick={handleClick}
    >{`${startDate?.slice(0, 10)} - ${endDate?.slice(0, 10)}`}</button>
  );
};

export default DateRangePickerMui;
