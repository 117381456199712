import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { schema } from "./schema/scrapeFindMessage";
import { useSnackbar } from "notistack";

const useFindMessage = () => {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { mutateAsync, isLoading, error, isError } = useMutateQuery();

  const { enqueueSnackbar } = useSnackbar();

  const downloadExcel = (jsonData) => {
    // console.log(jsonData?.data);

    const modifiedData = jsonData?.data.map((item) => {
      return {
        "Channel Name": item["Channel Name"],
        "Channel Username": item["Channel Username"],
        Text: item.Text,
        Date: item?.Date,
        Comment: item?.Comments,
        "Message Type": item["Message Type"],
        "Message URL": item["Message URL"],
        "Number of Replies": +item["Number of Replies"],
        "Total Reactions": +item["Total Reactions"],
        Forwards: +item?.Forwards,
        Views: +item?.Views,
        Engagement: +item?.Engagement,
        Edited: item?.Edited,
      };
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${new Date().toISOString()}.xlsx`);
  };

  const onSubmit = async (data: any) => {
    const res = await mutateAsync({
      url: "/fetch_single_message",
      body: {
        message_id: data?.link,
        flag_replies: data?.withReplies,
      },
      method: "post",
    });

    const result = Papa.parse(res, {
      header: true,
      skipEmptyLines: true,
    });

    downloadExcel(result);
  };

  useEffect(() => {
    if (isError) {
      try {
        let errorMsg: any = error;
        enqueueSnackbar(errorMsg?.response?.data, { variant: "error" });
      } catch (error) {
        enqueueSnackbar("something went wrong", { variant: "error" });
      }
    }
  }, [isError]);

  const findMessageLogic = {
    control,
    errors,
    onSubmit,
    theme,
    isLoading,
    handleSubmit,
  };

  return findMessageLogic;
};

export default useFindMessage;
