import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import ScrapeLink from "./components/scrape-link/scrape-link";
import {
  CustomTabPanel,
  a11yProps,
} from "../../components/shared/custom-tab-pannel/custom-tab-pannel";
import "./scrape-channel.css";
import FindMessageWithComments from "./components/find-message-with-comments/find-message-with-comments";
import FindMessage from "./components/find-message/find-message";
import { useSearchParams } from "react-router-dom";
import useScrapeLink from "./components/scrape-link/use-scrape-link";
import useFindMessageWithComments from "./components/find-message-with-comments/use-find-message-with-comments";
import useFindMessage from "./components/find-message/use-find-message";
import Navbar from "../../components/navbar/navbar";

const ScrapeChannel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = parseInt(searchParams.get("tab") || "0", 10);
  const [value, setValue] = React.useState(tab);

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const scrapeLinkLogic = useScrapeLink();
  const findMessageWithCommentsLogic = useFindMessageWithComments();
  const findMessageLogic = useFindMessage();

  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "#F8F8F8",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: "100vh",
          paddingTop: "100px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            height: "fit-content",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            width: "1000px",
            maxWidth: "100%",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Box className="background_link_form">
            <Box className="link_form_overlay"></Box>
          </Box>
          <Box className="form-content">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  sx={{
                    "*": {
                      minHeight: "unset !important",
                      textTransform: "none",
                    },
                  }}
                >
                  <Tab
                    sx={{ marginTop: "5px" }}
                    label="Channel"
                    icon={
                      scrapeLinkLogic?.isLoading &&
                      value !== 0 && (
                        <CircularProgress size={14} color="inherit" />
                      )
                    }
                    iconPosition="end"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ marginTop: "5px" }}
                    label="Message Comments"
                    icon={
                      findMessageWithCommentsLogic?.isLoading &&
                      value !== 1 && (
                        <CircularProgress size={14} color="inherit" />
                      )
                    }
                    iconPosition="end"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ marginTop: "5px" }}
                    label="Single Message"
                    icon={
                      findMessageLogic?.isLoading &&
                      value !== 2 && (
                        <CircularProgress size={14} color="inherit" />
                      )
                    }
                    iconPosition="end"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ScrapeLink scrapeLinkLogic={scrapeLinkLogic} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <FindMessageWithComments
                  findMessageWithCommentsLogic={findMessageWithCommentsLogic}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <FindMessage findMessageLogic={findMessageLogic} />
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ScrapeChannel;
