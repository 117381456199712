import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrapeChannel from "./features/scrape-channel/scrape-channel";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import Login from "./features/login/login";
import "./scss/App.scss";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route index element={<ScrapeChannel />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
