import {
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  alpha,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomTextField } from "../../../../components/shared/input/BootStrapInput";
import useFindMessage from "./use-find-message";

const FindMessage = ({ findMessageLogic }) => {
  const { control, errors, onSubmit, theme, isLoading, handleSubmit } =
    findMessageLogic;

  return (
    <>
      <Typography variant="h6">
        Get <span style={{ color: theme.palette.primary.main }}>content </span>{" "}
        from your single message link
      </Typography>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <FormLabel>Message link</FormLabel>
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <CustomTextField
              {...field}
              type="link"
              placeholder="https://t.me/channelName/2874"
              className={errors?.link?.message ? "error" : ""}
              sx={{ border: `1px solid ${theme.palette.divider}` }}
            />
          )}
        />
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors?.link?.message}
        </FormHelperText>
      </FormControl>
      <FormControlLabel
        sx={{ marginTop: "10px", marginLeft: "10px" }}
        control={
          <Controller
            name="withReplies"
            control={control}
            render={({ field }) => <Checkbox {...field} />}
          />
        }
        label="Includes Replies"
      />
      <FormHelperText sx={{ marginLeft: "52px", marginTop: "-5px" }}>
        The Process May take longer time
      </FormHelperText>
      <Button
        sx={{
          marginTop: "20px",
          marginRight: "auto",
          display: "flex",
          textTransform: "none",
          padding: "12px 25px",
          fontSize: "14px",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "&:disabled": {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            color: theme.palette.primary.contrastText,
          },
        }}
        onClick={handleSubmit(onSubmit)}
        endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        disabled={isLoading || errors?.link?.message}
      >
        Send Link
      </Button>
    </>
  );
};

export default FindMessage;
