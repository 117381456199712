import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./main.css";
import { ThemeModeProvider } from "./context/theme-mode-context";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/global-state-context";

LicenseInfo.setLicenseKey(
  "43e9876fb05781bfa14900a61e79d2a7Tz1NVUktNjI5OTksRT0xMDI5NzAyMzY0NzU1NyxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://1ee0c20da62957cbc9d0de7e70fbece8@o4507604923908096.ingest.de.sentry.io/4507605038071888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <QueryClientProvider client={queryClient}>
        <ThemeModeProvider>
          <SnackbarProvider maxSnack={3}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SnackbarProvider>
        </ThemeModeProvider>
      </QueryClientProvider>
    </CacheBuster>
  </React.StrictMode>
);
