import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Snackbar,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
// import logo from "../../assets/login_page.png";
import {
  GlobalStateContext,
  GlobalStateContextType,
} from "../../context/global-state-context";
import "./login.scss";
import { schema } from "./schema/LoginSchema";

import { Link } from "react-router-dom";
import { useMutateQuery } from "../../api/useMutateQuery";
import { useSnackbar } from "notistack";
import BrowserTabTitle from "../../components/shared/browser-tab-title/BrowserTabTitle";
import { CustomTextField } from "../../components/shared/input/BootStrapInput";

type LoginFormType = {
  username?: string;
  password?: string;
};

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const { user, setUser } =
    useContext<GlobalStateContextType>(GlobalStateContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormType>({ resolver: yupResolver(schema) });

  // const { mutateAsync, isSuccess, data, isError, isLoading } = useMutateQuery();

  const { state } = useLocation();

  useEffect(() => {
    if (user?.loggedIn) {
      navigate(state?.from?.pathname ?? "/");
    }
  }, [user?.loggedIn]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     navigate("/");
  //     localStorage.setItem("user", JSON.stringify(data.data.user));
  //     localStorage.setItem("token", data.data.token);
  //     setUser({ loggedIn: true });
  //   }
  // }, [isSuccess]);

  const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
    // setOpenWarning(true);
    // await mutateAsync({ ...data });
    if (data.username === "admin12" && data.password === "icd@icd&82pqhc") {
      localStorage.setItem(
        "user",
        JSON.stringify({
          username: "admin12",
          role: "admin",
          id: 1,
        })
      );
      localStorage.setItem(
        "token",
        "cidugdcigcd68t863td76d3g7dg73dgd37g3d7d3gd37g3d3d87d637d.d373td386dt3d3d83h83dhd3d3dd38dghuhgihbxjioqjoqksqmoioijo"
      );
      setUser({ loggedIn: true, username: "admin12", role: "admin" });
      navigate("/");
      enqueueSnackbar("Login Successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar("Username or Password is Incorrect", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  //   const [open, setOpen] = useState(false);
  //   const [openWarning, setOpenWarning] = useState(false);

  //   const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }

  //     setOpen(false);
  //   };

  // useEffect(() => {
  //   if (isError) {
  //     enqueueSnackbar("اسم المستخدم او كلمة المرور غير صحيحة", {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // }, [isError]);

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <BrowserTabTitle title={"Login"}>
      <div className="login__page row">
        <div className="col-6 right-side">
          <div
            style={{
              backgroundColor: "black",
              opacity: "0.3",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
        <div className="col-6 left-side col-md-12">
          <section className="containter__form">
            {/* <img
              style={{ width: "180px" }}
              className="logo__login"
              src={logo}
              alt="PTR"
            /> */}
            <div
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#888",
                marginBottom: "70px",
              }}
            >
              Please fill your information to login
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                {/* <label
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    fontWeight: "700",
                  }}
                  htmlFor="bootstrap-input1"
                >
                  Username
                  <span style={{ color: "red" }}>*</span>
                </label> */}
                <CustomTextField
                  // label="اسم المستخدم"
                  // error={!!errors?.username?.message}
                  className={errors?.username?.message && "error"}
                  placeholder="Enter Username"
                  {...register("username")}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.username?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ width: "100%", marginTop: "12px" }}
                variant="standard"
              >
                <CustomTextField
                  // label="كلمة المرور"
                  className={`password-icon-container ${
                    errors?.password?.message ? "error" : ""
                  }`}
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  // error={!!errors?.password?.message}
                  sx={{ position: "relative" }}
                />
                <IconButton
                  className="password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{ width: "40px", height: "40px", position: "absolute" }}
                >
                  {showPassword ? (
                    <VisibilityIcon className="show-password-icon" />
                  ) : (
                    <VisibilityOffIcon className="show-password-icon" />
                  )}
                </IconButton>
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.password?.message}
                </FormHelperText>
              </FormControl>
              {/* {isLoading && (
                <>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={3000}
                  >
                    <Alert
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      loading....
                    </Alert>
                  </Snackbar>
                </>
              )} */}
              <Button
                // style={{ marginTop: "10px" }}
                type="submit"
                sx={{
                  marginTop: "10px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  width: "100%",
                  borderRadius: "6px",
                  padding: "10px 0px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                  },
                }}
                value="login"
              >
                Login
              </Button>

              {/* {isError ? (
                <>
                  <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      username or password are incorrect
                    </Alert>
                  </Snackbar>
                </>
              ) : (
                ""
              )} */}
            </form>
          </section>
        </div>
      </div>
    </BrowserTabTitle>
  );
};

export default Login;
