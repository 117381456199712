import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema/scrapeChannelSchema";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import Papa from "papaparse";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material";
import { useForm } from "react-hook-form";

const INITIAL_DATE_RANGE: DateRange<Dayjs> = [
  dayjs().subtract(30, "day").add(3, "hours"),
  dayjs().add(3, "hours"),
];

const useScrapeLink = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<any>(
    INITIAL_DATE_RANGE[0].toISOString()
  );
  const [endDate, setEndDate] = useState<any>(
    INITIAL_DATE_RANGE[1].toISOString()
  );

  const open = Boolean(anchorEl);

  const theme = useTheme();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { mutateAsync, isLoading, isError, error } = useMutateQuery();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateRange = (e: any) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  useEffect(() => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
  }, [startDate, endDate]);

  const downloadExcel = (jsonData) => {
    const modifiedData = jsonData?.data.map((item) => {
      return {
        "Channel Name": item["Channel Name"],
        "Channel Username": item["Channel Username"],
        Text: item.Text,
        Date: item?.Date,
        Comment: item?.Comments,
        "Message Type": item["Message Type"],
        "Message URL": item["Message URL"],
        "Number of Replies": +item["Number of Replies"],
        "Total Reactions": +item["Total Reactions"],
        Forwards: +item?.Forwards,
        Views: +item?.Views,
        Engagement: +item?.Engagement,
        Edited: item?.Edited,
      };
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${startDate?.slice(0, 10)}/${endDate?.slice(0, 10)}.xlsx`
    );
  };

  const onSubmit = async (data: any) => {
    const res = await mutateAsync({
      url: "/scrape",
      body: {
        channel_username: data?.link,
        start_date: startDate,
        end_date: endDate,
        flag_replies: data?.withReplies,
      },
      method: "post",
    });

    const result = Papa.parse(res, {
      header: true,
      skipEmptyLines: true,
    });

    downloadExcel(result);
  };

  useEffect(() => {
    if (isError) {
      try {
        let errorMsg: any = error;
        enqueueSnackbar(errorMsg?.response?.data, { variant: "error" });
      } catch (error) {
        enqueueSnackbar("something went wrong", { variant: "error" });
      }
    }
  }, [isError]);

  const scrapeLinkLogic = {
    control,
    errors,
    handleClick,
    anchorEl,
    open,
    handleClose,
    startDate,
    endDate,
    theme,
    handleSubmit,
    onSubmit,
    handleDateRange,
    isLoading,
  };
  
  return scrapeLinkLogic;
};

export default useScrapeLink;
