import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  { name: "المناصب", path: "/positions" },
  { name: "المديريات", path: "/directorates" },
  // { name: "التواصل", path: "/contact" },
  { name: "الحدث", path: "/create-event" },
  { name: "المشاريع", path: "/" },
];

export default function Navbar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeNavItem, setActiveNavItem] = React.useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Offline Tracker
      </Typography>
      <Divider />
      <List>
        {/* {navItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <NavLink to={item.path}>
                <ListItemText primary={item.name} />
              </NavLink>
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );

  useEffect(() => {
    setActiveNavItem(`/${window.location.pathname.split("/")[1]}`);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          bgcolor: theme.palette.common.white,
          boxShadow: "0 2px 20px 5px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: theme.palette.primary.main }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              color: theme.palette.common.black,
            }}
          >
            Telegram Scraper
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "flex" }, gap: "50px" }}>
            {/* {navItems.map((item) => (
              <NavLink to={item.path} key={item.path}>
                <Button
                  key={item.path}
                  sx={{
                    color:
                      activeNavItem === item.path
                        ? theme.palette.primary.main
                        : theme.palette.common.black,
                    fontWeight: 400,
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      height: "3px",
                      width: activeNavItem === item.path ? "100%" : "0",
                      bottom: "-15px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: theme.palette.primary.main,
                      transition: "width 0.3s ease",
                    },
                  }}
                  onClick={() => setActiveNavItem(item.path)}
                >
                  {item.name}
                </Button>
              </NavLink>
            ))} */}
            <Button
              onClick={() => {
                localStorage.clear();
                navigate("/login");
                window.location.reload();
              }}
              sx={{
                // color: theme.palette.common.black,
                color: "red",
                fontWeight: 400,
                position: "relative",
                textTransform: "capitalize",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  height: "3px",
                  bottom: "-15px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: theme.palette.primary.main,
                  transition: "width 0.3s ease",
                },
              }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
