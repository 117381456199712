import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Menu,
  Typography,
  alpha,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomTextField } from "../../../../components/shared/input/BootStrapInput";
import DateRangePickerMui from "../../../../components/shared/date-range-picker-mui/DateRangePickerMui";
import BasicRangeShortcuts from "../../../../components/shared/date-range-picker-mui/CustomShortcutItem";
import useScrapeLink from "./use-scrape-link";

const ScrapeLink = ({ scrapeLinkLogic }) => {
  const {
    control,
    errors,
    handleClick,
    anchorEl,
    open,
    handleClose,
    startDate,
    endDate,
    theme,
    handleSubmit,
    onSubmit,
    handleDateRange,
    isLoading,
  } = scrapeLinkLogic;

  return (
    <>
      <Typography variant="h6">
        Get{" "}
        <span style={{ color: theme.palette.primary.main }}>all messages </span>{" "}
        from your chosen telegram channel
      </Typography>
      <Box sx={{ marginTop: "20px" }}></Box>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <FormLabel>Channel link</FormLabel>
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={12}>
                <CustomTextField
                  {...field}
                  type="link"
                  placeholder="https://t.me/channelName"
                  className={errors?.link?.message ? "error" : ""}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "-22px",
                    alignItems: "flex-start",
                  }}
                >
                  <FormLabel>Start - End date</FormLabel>
                  <DateRangePickerMui
                    startDate={startDate}
                    endDate={endDate}
                    handleClick={handleClick}
                  />
                </Box>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <BasicRangeShortcuts handleDateRange={handleDateRange} />
                </Menu>
              </Grid>
            </Grid>
          )}
        />
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors?.link?.message}
        </FormHelperText>
      </FormControl>
      <Box>
        <FormControlLabel
          sx={{
            // marginLeft: "10px",
            marginTop: "20px",
          }}
          control={
            <Controller
              name="withReplies"
              control={control}
              render={({ field }) => <Checkbox {...field} />}
            />
          }
          label="Includes Replies"
        />
        <FormHelperText sx={{ marginLeft: "30px", marginTop: "-8px" }}>
          The Process May take longer time
        </FormHelperText>
      </Box>
      <Button
        sx={{
          marginTop: "20px",
          marginRight: "auto",
          display: "flex",
          textTransform: "none",
          padding: "12px 25px",
          fontSize: "14px",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "&:disabled": {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            color: theme.palette.primary.contrastText,
          },
        }}
        onClick={handleSubmit(onSubmit)}
        endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        disabled={isLoading || errors?.link?.message}
      >
        Send Link
      </Button>
    </>
  );
};

export default ScrapeLink;
